define("discourse/plugins/discourse-ai/discourse/components/rag-options", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/modifier", "@ember/object", "discourse/components/d-tooltip", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _modifier, _object, _dTooltip, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RagOptions extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "showIndexingOptions", [_tracking.tracked], function () {
      return false;
    }))();
    #showIndexingOptions = (() => (dt7948.i(this, "showIndexingOptions"), void 0))();
    toggleIndexingOptions(event) {
      this.showIndexingOptions = !this.showIndexingOptions;
      event.preventDefault();
      event.stopPropagation();
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleIndexingOptions", [_object.action]))();
    get indexingOptionsText() {
      return this.showIndexingOptions ? _discourseI18n.default.t("discourse_ai.rag.options.hide_indexing_options") : _discourseI18n.default.t("discourse_ai.rag.options.show_indexing_options");
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @model.rag_uploads}}
          <a
            href="#"
            class="rag-options__indexing-options"
            {{on "click" this.toggleIndexingOptions}}
          >{{this.indexingOptionsText}}</a>
        {{/if}}
    
        {{#if this.showIndexingOptions}}
          <div class="control-group">
            <label>{{I18n.t "discourse_ai.rag.options.rag_chunk_tokens"}}</label>
            <Input
              @type="number"
              step="any"
              lang="en"
              class="rag-options__rag_chunk_tokens"
              @value={{@model.rag_chunk_tokens}}
            />
            <DTooltip
              @icon="circle-question"
              @content={{I18n.t "discourse_ai.rag.options.rag_chunk_tokens_help"}}
            />
          </div>
          <div class="control-group">
            <label>{{I18n.t
                "discourse_ai.rag.options.rag_chunk_overlap_tokens"
              }}</label>
            <Input
              @type="number"
              step="any"
              lang="en"
              class="rag-options__rag_chunk_overlap_tokens"
              @value={{@model.rag_chunk_overlap_tokens}}
            />
            <DTooltip
              @icon="circle-question"
              @content={{I18n.t
                "discourse_ai.rag.options.rag_chunk_overlap_tokens_help"
              }}
            />
          </div>
          {{yield}}
        {{/if}}
      
    */
    {
      "id": "f2OHyYF7",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"rag_uploads\"]],[[[1,\"      \"],[11,3],[24,6,\"#\"],[24,0,\"rag-options__indexing-options\"],[4,[32,0],[\"click\",[30,0,[\"toggleIndexingOptions\"]]],null],[12],[1,[30,0,[\"indexingOptionsText\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showIndexingOptions\"]],[[[1,\"      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,[28,[32,1,[\"t\"]],[\"discourse_ai.rag.options.rag_chunk_tokens\"],null]],[13],[1,\"\\n        \"],[8,[32,2],[[24,\"step\",\"any\"],[24,\"lang\",\"en\"],[24,0,\"rag-options__rag_chunk_tokens\"]],[[\"@type\",\"@value\"],[\"number\",[30,1,[\"rag_chunk_tokens\"]]]],null],[1,\"\\n        \"],[8,[32,3],null,[[\"@icon\",\"@content\"],[\"circle-question\",[28,[32,1,[\"t\"]],[\"discourse_ai.rag.options.rag_chunk_tokens_help\"],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,[28,[32,1,[\"t\"]],[\"discourse_ai.rag.options.rag_chunk_overlap_tokens\"],null]],[13],[1,\"\\n        \"],[8,[32,2],[[24,\"step\",\"any\"],[24,\"lang\",\"en\"],[24,0,\"rag-options__rag_chunk_overlap_tokens\"]],[[\"@type\",\"@value\"],[\"number\",[30,1,[\"rag_chunk_overlap_tokens\"]]]],null],[1,\"\\n        \"],[8,[32,3],null,[[\"@icon\",\"@content\"],[\"circle-question\",[28,[32,1,[\"t\"]],[\"discourse_ai.rag.options.rag_chunk_overlap_tokens_help\"],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[18,2,null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@model\",\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/rag-options.js",
      "scope": () => [_modifier.on, _discourseI18n.default, _component2.Input, _dTooltip.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = RagOptions;
});